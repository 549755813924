'use client'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/ui/Dialog.tsx'
import isBrowser from '@/utils/common/isBrowser.ts'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'
import { useLocalStorage } from 'react-use'
import { z } from 'zod'
import MarketingButton, { type MarketingButtonProps } from '../Button.tsx'
import MarketingCheckEmailForm from '../CheckEmailForm.tsx'

type LandingPageHeroProductTourButtonProps = MarketingButtonProps

const portalId = '8293589'
const formId = 'a9e9ea70-794c-4714-8972-acd876821d3f'
const formSubmissionURL =
  'https://api.hsforms.com/submissions' +
  `/v3/integration/submit/${portalId}/${formId}`

const BLOCKED_EMAIL_DOMAINS = [
  'aol.com',
  'email.com',
  'gmail.com',
  'hotmail.com',
  'icloud.com',
  'inbox.com',
  'mail.com',
  'outlook.com',
  'protonmail.com',
  'test.com',
  'yahoo.com',
  'yandex.com',
]

const productTourFormSchema = z.object({
  email: z
    .string()
    .trim()
    .email()
    .refine((email) => !BLOCKED_EMAIL_DOMAINS.includes(email.split('@')[1]), {
      message: 'Please enter a valid work email address',
    }),
})

export default function LandingPageHeroProductTourButton(
  props: LandingPageHeroProductTourButtonProps,
) {
  const [error, setError] = useState<string>()

  const [
    localStorageProductTourHasSubmittedForm,
    setLocalStorageProductTourHasSubmittedForm,
  ] = useLocalStorage<boolean>('sirvatus.product-tour.has-submitted-form')

  const productTourMutation = useMutation({
    mutationFn: (data: z.infer<typeof productTourFormSchema>) => {
      if (['development', 'test'].includes(process.env.APP_ENV)) {
        return new Promise((resolve) => resolve(undefined))
      }
      return fetch(formSubmissionURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${new Date().getTime()}`,
          fields: [
            {
              objectTypeId: '0-1',
              name: 'email',
              value: data.email,
            },
          ],
          context: {
            pageUri: isBrowser()
              ? window.location.href
              : 'https://www.sirvatus.com',
            pageName: document.title,
          },
        }),
      })
    },
    onSuccess: () => {
      setLocalStorageProductTourHasSubmittedForm(true)
    },
  })

  return (
    <Dialog>
      <DialogTrigger asChild>
        <MarketingButton {...props} />
      </DialogTrigger>
      <DialogContent
        className={clsx(
          localStorageProductTourHasSubmittedForm &&
            // Hide dialog and close button
            'flex h-0 w-0 items-center justify-center has-[svg]:[&_button]:hidden',
        )}
        data-testid="product-tour-dialog"
      >
        {localStorageProductTourHasSubmittedForm ? (
          <video controls className="w-[calc(100vw-2rem)] max-w-5xl">
            <source
              src="https://res.cloudinary.com/sirvatus/video/upload/v1708994365/assets/product-video-short_pafsof.mp4"
              type="video/mp4"
            />
          </video>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Sirvatus Product Tour Video</DialogTitle>
              {!localStorageProductTourHasSubmittedForm && (
                <DialogDescription>
                  Please enter your work email address below to view
                </DialogDescription>
              )}
            </DialogHeader>

            <MarketingCheckEmailForm
              error={error}
              onSubmit={async (data) => {
                setError(undefined)
                const parsedData = productTourFormSchema.safeParse(data)
                if (!parsedData.success) {
                  setError(
                    parsedData.error.errors?.[0].message ?? 'Invalid form data',
                  )
                  return
                }
                await productTourMutation.mutateAsync(parsedData.data)
              }}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
